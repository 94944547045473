import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

const Paragraph = styled.p.attrs(
  props => {
    const {
      className,
      uppercase,
      withMeasure
    } = props;

    return {
      className: classNames(className, "paragraph", {
          "paragraph--uppercase": uppercase,
          "paragraph--with-measure": withMeasure
        })
    };
  }
)`
  ${({ theme: { colors, fonts, fontWeights } }) => `
    font: inherit;
    font-family: inherit;

    &.paragraph--uppercase {
      text-transform: uppercase;
    }
  `}
`;

Paragraph.propTypes = {
  expanded: PropTypes.bool,
  uppercase: PropTypes.bool,
  withMeasure: PropTypes.bool
};

Paragraph.defaultProps = {
  expanded: false,
  uppercase: false,
  withMeasure: false
};

export default Paragraph;
